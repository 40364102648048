export default {
  fleetAnalytics: {
    dashboardHeader: "Dashboard: Fleet Analytics (Flottenanalyse)",
    dataTable: {
      headers: {
        contacts: "Kontakte",
        machines: "Maschinen",
        segments: "Segmente",
        tenant: "Mieter"
      },
      machines: {
        count: "Anzahl",
        engineHours: "Gearbeitet",
        serviceAgreements: "Serviceverträge"
      }
    },
    widgets: {
      segments: "Auslastung pro Segment",
      utilization: "Auslastung pro Modell"
    }
  }
}

export default {
  $vuetify: {
    badge: "Badge",
    calendar: {
      moreEvents: "{0} altri"
    },
    carousel: {
      ariaLabel: {
        delimiter: "Diapositiva della sequenza {0}di {1}"
      },
      next: "Immagine successiva",
      prev: "Immagine precedente"
    },
    close: "Chiudi",
    dataFooter: {
      firstPage: "Prima pagina",
      itemsPerPageAll: "Tutto",
      itemsPerPageText: "Oggetti per pagina:",
      lastPage: "Ultima pagina",
      nextPage: "Pagina successiva",
      pageText: "{0}-{1} di {2}",
      prevPage: "Pagina precedente"
    },
    dataIterator: {
      loadingText: "Caricamento in corso... Attendere",
      noResultsText: "Nessuna corrispondenza trovata"
    },
    dataTable: {
      ariaLabel: {
        activateAscending: "Attiva per ordinare in modo crescente.",
        activateDescending: "Attiva per ordinare in modo decrescente.",
        activateNone: "Attiva per rimuovere l'ordinamento.",
        sortAscending: "Ordinato in ordine crescente.",
        sortDescending: "Ordinato in ordine decrescente.",
        sortNone: "Non ordinato."
      },
      itemsPerPageText: "Righe per pagina:",
      sortBy: "Ordina per"
    },
    datePicker: {
      itemsSelected: "{0} selezionati",
      nextMonthAriaLabel: "Mese successivo",
      nextYearAriaLabel: "Anno successivo",
      prevMonthAriaLabel: "Mese precedente",
      prevYearAriaLabel: "Anno precedente"
    },
    fileInput: {
      counter: "{0} file",
      counterSize: "{0} file ({1} in totale)"
    },
    noDataText: "Nessun dato disponibile",
    pagination: {
      ariaLabel: {
        currentPage: "Pagina corrente Pagina {0}",
        next: "Pagina successiva",
        page: "Vai alla pagina {0}",
        previous: "Pagina precedente",
        wrapper: "Struttura impaginazione"
      }
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    }
  },
  chart: {
    legend: {
      average: "Media",
      hours: "Ore",
      max: "Max.",
      pressure: "Pressione",
      temperature: "Temperatura"
    }
  },
  common: {
    actions: {
      add: "Aggiungi",
      apply: "Applica",
      applyChanges: "Applica modifiche",
      cancel: "Annulla",
      close: "Chiudi",
      download: "Scarica",
      edit: "Modifica",
      exportAsCSV: "Esportazione come csv",
      goBack: "Torna indietro",
      hide: "Nascondi",
      move: "Sposta",
      next: "Successivo",
      no: "No",
      ok: "OK",
      openInNewWindow: "Si apre in una nuova finestra",
      previous: "Precedente",
      refresh: "Aggiorna",
      remove: "Rimuovere un utente",
      retry: "Riprova",
      save: "Salva",
      show: "Mostra",
      transfer: "Trasferimento",
      view: "Visualizzazione",
      yes: "Sì"
    },
    allRightsReserved: "Tutti i diritti riservati",
    dataTable: {
      headers: {
        actions: "Azioni"
      }
    },
    default: "Predefinito",
    dialogs: {
      confirmRemove: {
        confirm: "Rimuovere il {0}?",
        note: "Nota: Questa azione non può essere annullata.",
        title: "Rimuovi {0}"
      }
    },
    enums: {
      day: {
        now: "Ora",
        today: "Oggi",
        yesterday: "Ieri"
      },
      measurementSystem: {
        imperial: "Imperiale",
        metric: "Metrica"
      },
      time: {
        hours: "Ore",
        minutes: "Minuti",
        seconds: "Secondi"
      }
    },
    error: {
      causedBy: "Causato da",
      details: "Dettagli",
      more: "Altro",
      request: "Richiesta"
    },
    exceptions: {
      error: "Errore"
    },
    filters: {
      customRange: "Intervallo personalizzato",
      filter: "Filtro",
      last: "Ultimo",
      timeRange: "Intervallo di tempo"
    },
    invalidUsage: "Utilizzo non valido",
    language: "Lingua",
    messages: {
      noDataToExport:
        "Non ci sono dati da esportare. Seleziona un intervallo di tempo diverso.",
      recordsExported: "{number} record sono stati esportati"
    },
    placeholders: {
      email: "Inserisci indirizzo e-mail",
      selectDates: "Seleziona le date"
    },
    saveSuceeded: "Le modifiche sono state salvate",
    units: {
      day: "{count} giorni| {count} giorno| {count} giorni",
      daysAgo: "{count} giorni fa| {count} giorno fa| {count} giorni fa",
      hour: "{count} ore| {count} ora| {count} ore",
      hourShort: "H",
      month: "{count} mesi| {count} mese | {count} mesi",
      second: "{count} secondi| {count} secondo | {count} secondi",
      week: "{count} settimane | {count} settimana | {count} settimane"
    },
    validation: {
      errors: {
        betweenInvalid: "Il valore deve essere compreso tra{MIN} e {MAX}",
        commentMaxChars: "Il commento deve contenere 256 caratteri al massimo",
        customerNoInvalid: "Inserisci un codice cliente valido ",
        discountInvalid:
          "Il valore deve essere numerico e compreso tra 0 e 100",
        emailInvalid: "Indirizzo e-mail non valido",
        emailLengthInvalid: "Lunghezza dell'indirizzo e-mail non valida.",
        engineHoursInvalid: "Ore motore non valide",
        fieldIsRequired: "Obbligatorio",
        fieldLengthBetween: "Deve essere tra {MIN} e {MAX} caratteri",
        fieldMustBeginWithWord: "Deve iniziare con un carattere di parola",
        latitudeInvalid:
          "La latitudine deve essere un numero compreso tra -90 e 90",
        longitudeInvalid:
          "La longitudine deve essere un numero compreso tra -180 e 180",
        maxNumberOfCharacters: "Max {NUMBER} caratteri",
        missingDigitInPassword: "La password deve avere almeno un numero (123)",
        missingLowerCaseInPassword:
          "La password deve avere almeno una lettera minuscola (abc)",
        missingSpecialCharInPassword:
          "La password deve avere almeno un carattere speciale (!@#)",
        missingUpperCaseInPassword:
          "La password deve avere almeno una lettera maiuscola (ABC)",
        numericFieldInvalid:
          "Il valore deve essere numerico e non iniziare con zero",
        numericFieldLengthInvalid: "Inserire un valore da {MIN} a {MAX} cifre",
        partNumberInvalid: "Numero pezzo non valido",
        passwordDoesNotMatch: "Le nuove password devono corrispondere",
        passwordTheSameAsOldOne:
          "La nuova password non può essere uguale alla vecchia password",
        passwordTooLong: "Il numero massimo di caratteri della password è 50",
        passwordTooShort: "La password deve avere almeno di 8 caratteri",
        priceListIdInvalid:
          "Il valore deve iniziare con 2 lettere maiuscole e contenere 2 cifre",
        seriaNoInvalid: "Numero di serie non valido",
        seriaNoItemExist: "Il numero di serie è già utilizzato",
        seriaNoListEmpty: "L’elenco dei numeri di serie non può essere vuoto",
        transferCodeInvalid: "Codice di trasferimento non valido"
      }
    }
  },
  login: {
    buttons: {
      labels: {
        sendPasswordResetEmail: "Invio"
      }
    },
    changePassword: "Cambia la password",
    confirmNewPassword: "Conferma la nuova password",
    contactUs: "Contattaci",
    email: "E-mail",
    forgotPassword: "Password dimenticata",
    forgotPasswordQuestion: "Password dimenticata?",
    newPassword: "Nuova password",
    oldPassword: "Vecchia password",
    password: "Password",
    passwordHasChanged: "La tua password è stata modificata",
    passwordResetCheckEmail:
      "Controlla la tua e-mail per ulteriori istruzioni su come reimpostare la password.",
    passwordResetDescription:
      "Inserisci l'indirizzo e-mail verificato del tuo account utente, ti invieremo un link per reimpostare la password.",
    passwordStrength: "Sicurezza della password",
    pleaseLoginWithNewPassword: "Effettua il login con la tua nuova password",
    sessionHasExpired:
      "La tua sessione di accesso è scaduta. Esegui di nuovo il login.",
    signIn: "ACCEDI",
    signOut: "Esci"
  },
  mainMenu: {
    documents: "Documenti",
    downloads: "Scarica",
    help: "Aiuto",
    home: "Home",
    messages: "Messaggi",
    webshop: "Negozio online"
  },
  mapInfoWindow: {
    machineSerialNo: "Numero di serie",
    missingMachineImage: "Immagine del prodotto mancante"
  },
  messages: {
    title: "Messaggi"
  },
  store: {
    errors: {
      awsFailedToPutItem: "Impossibile inserire l’elemento in AWS",
      couldNotConnectToBackendServer:
        "Impossibile connettersi al server di backend",
      error: "Errore",
      failedToAddItem: "Impossibile aggiungere l'elemento",
      failedToDeleteUser: "Impossibile eliminare l'utente",
      failedToFechItem: "Impossibile recuperare l'elemento",
      failedToFetchUserDetails: "Impossibile recuperare i dettagli dell'utente",
      failedToSaveItem: "Impossibile salvare l'elemento",
      failedToSendEmailNotification: "Impossibile inviare la notifica e-mail",
      failedToUpdateUserDetails: "Impossibile aggiornare i dettagli dell'utente"
    }
  },
  termsOfService: {
    agreeAndContinue: "Accetta e continua",
    agreeTerms: "Sono d'accordo {TERMS}e {POLICY}.",
    description:
      "Prima di poter iniziare l’utilizzo{APP_TITLE}, devi accettare i Termini di servizio e l'Informativa sulla privacy.",
    privacyPolicy: "Informativa sulla privacy",
    title: "Termini di servizio"
  }
}

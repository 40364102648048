export default {
  fleetAnalytics: {
    dashboardHeader: "Tableau de bord Fleet Analytics",
    dataTable: {
      headers: {
        contacts: "Contacts",
        machines: "Engins",
        segments: "Segments",
        tenant: "Locataire"
      },
      machines: {
        count: "Nombre",
        engineHours: "De fonctionnement",
        serviceAgreements: "Contrats d’entretien"
      }
    },
    widgets: {
      segments: "Utilisation par segment",
      utilization: "Utilisation par modèle"
    }
  }
}

export default {
  $vuetify: {
    badge: "Abzeichen",
    calendar: {
      moreEvents: "{0} mehr"
    },
    carousel: {
      ariaLabel: {
        delimiter: "Karussell-Rutsche {0} von {1}"
      },
      next: "Nächstes Bild",
      prev: "Vorheriges Bild"
    },
    close: "Schließen",
    dataFooter: {
      firstPage: "Erste Seite",
      itemsPerPageAll: "Alle",
      itemsPerPageText: "Elemente pro Seite:",
      lastPage: "Letzte Seite",
      nextPage: "Nächste Seite",
      pageText: "{0}-{1} von {2}",
      prevPage: "Vorherige Seite"
    },
    dataIterator: {
      loadingText: "Wird geladen ... Bitte warten",
      noResultsText: "Keine passenden Datensätze gefunden"
    },
    dataTable: {
      ariaLabel: {
        activateAscending:
          "Aktivieren Sie diese Option, um aufsteigend zu sortieren.",
        activateDescending:
          "Aktivieren Sie diese Option, um absteigend zu sortieren.",
        activateNone:
          "Aktivieren Sie diese Option, um die Sortierung aufzuheben.",
        sortAscending: "Aufsteigend sortiert.",
        sortDescending: "Absteigend sortiert.",
        sortNone: "Nicht sortiert."
      },
      itemsPerPageText: "Zeilen pro Seite:",
      sortBy: "Sortieren nach"
    },
    datePicker: {
      itemsSelected: "{0} ausgewählt",
      nextMonthAriaLabel: "Nächster Monat",
      nextYearAriaLabel: "Nächstes Jahr",
      prevMonthAriaLabel: "Vorheriger Monat",
      prevYearAriaLabel: "Voriges Jahr"
    },
    fileInput: {
      counter: "{0} Dateien",
      counterSize: "{0} Dateien ({1} insgesamt)"
    },
    noDataText: "Keine Daten verfügbar",
    pagination: {
      ariaLabel: {
        currentPage: "Aktuelle Seite Seite {0}",
        next: "Nächste Seite",
        page: "Gehe zu Seite {0}",
        previous: "Vorherige Seite",
        wrapper: "Paginierung Navigation"
      }
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    }
  },
  chart: {
    legend: {
      average: "Durchschnitt",
      hours: "Stunden",
      max: "Max.",
      pressure: "Druck",
      temperature: "Temperatur"
    }
  },
  common: {
    actions: {
      add: "Hinzufügen",
      apply: "Übernehmen",
      applyChanges: "Änderungen übernehmen",
      cancel: "Abbrechen",
      close: "Schließen",
      download: "Herunterladen",
      edit: "Bearbeiten",
      exportAsCSV: "Exportieren als .csv-Datei",
      goBack: "Zurück",
      hide: "Verbergen",
      move: "Verschieben",
      next: "Weiter",
      no: "Nein",
      ok: "OK",
      openInNewWindow: "Öffnet in einem neuen Fenster",
      previous: "Vorherige",
      refresh: "Aktualisieren",
      remove: "Entfernen",
      retry: "Erneut versuchen",
      save: "Speichern",
      show: "Zeigen",
      transfer: "Übertragen",
      view: "Ansicht",
      yes: "Ja"
    },
    allRightsReserved: "Alle Rechte vorbehalten",
    dataTable: {
      headers: {
        actions: "Aktionen"
      }
    },
    default: "Standard",
    dialogs: {
      confirmRemove: {
        confirm: "Sind Sie sicher, dass Sie {0} entfernen wollen?",
        note: "Hinweis: Diese Aktion kann nicht rückgängig gemacht werden.",
        title: "{0} entfernen"
      }
    },
    enums: {
      day: {
        now: "Jetzt",
        today: "Heute",
        yesterday: "Gestern"
      },
      measurementSystem: {
        imperial: "Imperiale Einheiten",
        metric: "Metrisch"
      },
      time: {
        hours: "Stunden",
        minutes: "Minuten",
        seconds: "Sekunden"
      }
    },
    error: {
      causedBy: "Verursacht durch",
      details: "Einzelheiten",
      more: "Mehr",
      request: "Anfrage"
    },
    exceptions: {
      error: "Fehler"
    },
    filters: {
      customRange: "Benutzerdefinierter Bereich",
      filter: "Filter",
      last: "Zuletzt",
      timeRange: "Zeitspanne"
    },
    invalidUsage: "Unzulässige Verwendung",
    language: "Sprache",
    messages: {
      noDataToExport:
        "Es gibt keine Daten für den Export. Bitte wählen Sie eine andere Zeitspanne.",
      recordsExported: "{number} Datensätze wurden exportiert"
    },
    placeholders: {
      email: "Geben Sie eine E-Mail Adresse ein",
      selectDates: "Datum auswählen"
    },
    saveSuceeded: "Die Änderungen wurden gespeichert",
    units: {
      day: "{count} Tage | {count} Tag | {count} Tage",
      daysAgo: "vor {count} Tagen | vor {count} Tagen | vor {count} Tagen",
      hour: "{count} Stunden | {count} Stunde | {count} Stunden",
      hourShort: "H",
      month: "{count} Monate | {count} Monat | {count} Monate",
      second: "{count} Sekunden | {count} Sekunde | {count} Sekunden",
      week: "{count} Wochen | {count} Woche | {count} Wochen"
    },
    validation: {
      errors: {
        betweenInvalid: "Der Wert muss zwischen {MIN} und {MAX} liegen",
        commentMaxChars: "Ein Kommentar darf maximal 256 Zeichen lang sein.",
        customerNoInvalid: "Bitte geben Sie eine gültige Kundennummer ein. ",
        discountInvalid:
          "Der Wert muss numerisch sein und zwischen 0 und 100 liegen. ",
        emailInvalid: "Ungültige E-Mail Adresse",
        emailLengthInvalid: "Ungültige Länge der E-Mail Adresse.",
        engineHoursInvalid: "Ungültige Motorbetriebsstunden",
        fieldIsRequired: "Erforderlich",
        fieldLengthBetween: "Muss zwischen {MIN} und {MAX} Zeichen liegen",
        fieldMustBeginWithWord: "Muss mit einem Wortzeichen beginnen",
        latitudeInvalid:
          "Der Breitengrad muss eine Zahl zwischen -90 und 90 sein",
        longitudeInvalid:
          "Der Längengrad muss eine Zahl zwischen -180 und 180 sein",
        maxNumberOfCharacters: "Maximal {NUMBER} Zeichen",
        missingDigitInPassword:
          "Das Passwort muss mindestens eine Zahl enthalten (123)",
        missingLowerCaseInPassword:
          "Das Passwort muss mindestens einen Kleinbuchstaben enthalten (abc)",
        missingSpecialCharInPassword:
          "Das Passwort muss mindestens ein Sonderzeichen enthalten (!@#)",
        missingUpperCaseInPassword:
          "Das Passwort muss mindestens einen Großbuchstaben enthalten (ABC)",
        numericFieldInvalid:
          "Der Wert muss numerisch sein und darf nicht mit Null beginnen",
        numericFieldLengthInvalid:
          "Bitte geben Sie einen Wert mit {MIN} bis {MAX} Ziffern ein",
        partNumberInvalid: "Ungültige Teilenummer",
        passwordDoesNotMatch: "Neue Passwörter müssen übereinstimmen",
        passwordTheSameAsOldOne:
          "Das neue Passwort kann nicht mit dem alten identisch sein",
        passwordTooLong: "Das Passwort darf maximal 50 Zeichen enthalten",
        passwordTooShort: "Das Passwort muss mindestens 8 Zeichen enthalten",
        priceListIdInvalid:
          "Der Wert muss mit 2 Großbuchstaben beginnen und 2 Ziffern enthalten.",
        seriaNoInvalid: "Ungültige Seriennummer",
        seriaNoItemExist: "Seriennummer wird schon verwendet",
        seriaNoListEmpty: "Seriennummernliste darf nicht leer sein",
        transferCodeInvalid: "Ungültiger Übertragungscode"
      }
    }
  },
  login: {
    buttons: {
      labels: {
        sendPasswordResetEmail: "Senden"
      }
    },
    changePassword: "Passwort ändern",
    confirmNewPassword: "Neues Passwort bestätigen",
    contactUs: "Kontakt",
    email: "E-Mail",
    forgotPassword: "Passwort vergessen",
    forgotPasswordQuestion: "Passwort vergessen?",
    newPassword: "Neues Passwort",
    oldPassword: "Altes Passwort",
    password: "Passwort",
    passwordHasChanged: "Ihr Passwort wurde geändert",
    passwordResetCheckEmail:
      "Bitte prüfen Sie Ihre E-Mail, um weitere Anweisungen zum Zurücksetzen Ihres Passworts zu erhalten.",
    passwordResetDescription:
      "Geben Sie die verifizierte E-Mail-Adresse Ihres Benutzerkontos ein, und wir senden Ihnen einen Link zum Zurücksetzen des Passworts.",
    passwordStrength: "Passwortstärke",
    pleaseLoginWithNewPassword:
      "Bitte melden Sie sich mit Ihrem neuen Passwort an",
    sessionHasExpired:
      "Ihre Anmeldesitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.",
    signIn: "ANMELDEN",
    signOut: "Abmelden"
  },
  mainMenu: {
    documents: "Dokumente",
    downloads: "Downloads",
    help: "Hilfe",
    home: "Startseite",
    messages: "Nachrichten",
    webshop: "Webshop"
  },
  mapInfoWindow: {
    machineSerialNo: "Seriennummer",
    missingMachineImage: "Fehlendes Produktbild"
  },
  messages: {
    title: "Nachrichten"
  },
  store: {
    errors: {
      awsFailedToPutItem: "Das Objekt konnte nicht in AWS abgelegt werden",
      couldNotConnectToBackendServer:
        "Keine Verbindung zum Backend-Server möglich",
      error: "Fehler",
      failedToAddItem: "Element kann nicht hinzugefügt werden",
      failedToDeleteUser: "Benutzer kann nicht gelöscht werden",
      failedToFechItem: "Element konnte nicht abgerufen werden",
      failedToFetchUserDetails:
        "Benutzerdetails konnten nicht abgerufen werden",
      failedToSaveItem: "Element konnte nicht gespeichert werden",
      failedToSendEmailNotification:
        "E-Mail-Benachrichtigung konnte nicht gesendet werden",
      failedToUpdateUserDetails:
        "Aktualisierung der Benutzerdaten fehlgeschlagen"
    }
  },
  termsOfService: {
    agreeAndContinue: "Zustimmen und fortfahren",
    agreeTerms: "Ich stimme den {TERMS} und der {POLICY} zu.",
    description:
      "Bevor Sie mit der Nutzung von {APP_TITLE} beginnen können, müssen Sie die Nutzungsbedingungen und die Datenschutzrichtlinie akzeptieren und Ihre Zustimmung erklären.",
    privacyPolicy: "Datenschutzrichtlinie",
    title: "Nutzungsbedingungen"
  }
}

export default {
  documents: {
    ce: {
      subTitle: "Documenti CE per Brokk {productId}",
      title: "CE"
    },
    diagrams: {
      subTitle: "Diagrammi per Brokk {productId}",
      title: "Diagrammi"
    },
    manuals: {
      subTitle: "Manuali per Brokk {productId}",
      title: "Manuali"
    },
    menuItems: {
      returnform: "Modulo di reso",
      serviceBulletins: "Bollettini di manutenzione",
      serviceInstructions: "Istruzioni di manutenzione",
      serviceManuals: "Manuali di manutenzione",
      serviceProtocols: "Protocolli di manutenzione",
      warranty: "Rivendicazioni di garanzia"
    },
    returnform: {
      description1: "Utilizzare questo modulo per restituire merci a Brokk AB.",
      description2:
        "Prima di inviare qualsiasi merce da restituire, contattare Brokk AB per l’omologazione.",
      description3:
        "Le merci ricevute presso Brokk AB senza omologazione verranno gettate.",
      subtitle: "Modulo di reso per merci Brokk."
    },
    serialNoRange: "N. di serie: {range}",
    serviceBulletins: {
      action: {
        add: "Aggiungi bollettini di manutenzione",
        addSerials: "Aggiungi numeri di serie",
        edit: "Modifica bollettini di manutenzione"
      },
      description:
        "Informazioni riguardanti il miglioramento, le ispezioni, la riparazione o la modifica di macchine e attrezzi Brokk.",
      dialogs: {
        errors: {
          couldNotAddServiceBulletins:
            "Impossibile aggiungere bollettini di manutenzione {msg}",
          couldNotRemoveServiceBulletins:
            "Impossibile rimuovere bollettini di manutenzione {msg}",
          couldNotUpdateServiceBulletins:
            "Impossibile aggiornare bollettini di manutenzione {msg}"
        }
      },
      itemName: "bollettini di manutenzione",
      messages: {
        failedToDownload: "Download non riuscito {file}",
        itemAdded: "Bollettini di manutenzione aggiunti.",
        itemUpdated: "Bollettini di manutenzione aggiornati.",
        updateDateInfo: "aggiornato {date}",
        updateDocumentInfo: "Documento {document} "
      },
      subtitle: "Documenti per i bollettini di manutenzione."
    },
    serviceDocuments: {
      action: {
        addSerials: "Aggiungi numeri di serie"
      },
      addEditItemForm: {
        descriptionLabel: "Descrizione",
        descriptionPlaceholder: "Inserisci descrizione",
        fileuploadLabel: "Fai clic qui per selezionare un file .pdf",
        machinesLabel: "Macchine",
        partNumberLabel: "Numero pezzo",
        partNumberPlaceholder: "Inserisci numero pezzo",
        serialLabel: "Numeri di serie",
        serialPlaceholder: "Inserisci numeri di serie separati da ','",
        uploadFilePlaceholder: "Carica un file"
      },
      datatable: {
        headers: {
          description: "Descrizione",
          machines: "Macchine",
          partNo: "N° pezzo"
        }
      },
      machineSerials: "Numeri di serie macchine"
    },
    serviceInstructions: {
      action: {
        add: "Aggiungi istruzione di manutenzione",
        addSerials: "Aggiungi numeri di serie",
        edit: "Modifica istruzione di manutenzione"
      },
      datatable: {
        headers: {
          machines: "Macchine",
          partNo: "N° pezzo",
          service: "Manutenzione"
        }
      },
      description:
        "Istruzioni sul modo in cui installare, sottoporre a manutenzione o regolare vari pezzi e kit.",
      dialogs: {
        errors: {
          couldNotAddServiceInstructions:
            "Impossibile aggiungere istruzione di manutenzione {msg}",
          couldNotRemoveServiceInstructions:
            "Impossibile rimuovere istruzione di manutenzione {msg}",
          couldNotUpdateServiceInstructions:
            "Impossibile aggiornare istruzione di manutenzione {msg}"
        }
      },
      itemName: "istruzione di manutenzione",
      machineSerials: "Numeri di serie macchine",
      messages: {
        failedToDownload: "Download non riuscito {file}",
        itemAdded: "Istruzioni di manutenzione aggiunte.",
        itemUpdated: "Istruzione di manutenzione aggiornata.",
        updateDateInfo: "aggiornato {date}",
        updateDocumentInfo: "Documento {document} "
      },
      subtitle: "Documenti per l’istruzione di manutenzione."
    },
    serviceManuals: {
      description:
        "Manuali riguardanti la manutenzione, l’assistenza e la riparazione. Disponibili soltanto per Brokk 40, 90, 110, 150, 180, 250, 330 e SP+.",
      subtitle: "Documenti per i manuali di manutenzione."
    },
    serviceProtocols: {
      description1:
        "Qui è possibile scaricare tutti i protocolli di manutenzione disponibili.",
      description2:
        "Il protocollo di manutenzione viene utilizzato quando si esegue la manutenzione regolare in conformità allo schema di manutenzione nel manuale Brokk.",
      subtitle: "Protocolli di manutenzione per macchine Brokk."
    },
    spareParts: {
      description: "",
      subtitle: "Documenti e link per la garanzia."
    },
    technicalData: {
      includedInManual:
        "I dati tecnici sono inclusi nel manuale. Consultare il manuale.",
      subTitle: "Dati tecnici per Brokk {productId}",
      title: "Dati tecnici"
    },
    troubleshooting: {
      includedInManual:
        "La ricerca dei guasti è inclusa nel manuale. Consultare il manuale.",
      subTitle: "Ricerca dei guasti per Brokk {productId}",
      title: "Ricerca dei guasti"
    },
    warranty: {
      description: "",
      subtitle: "Documenti e link per la garanzia."
    }
  }
}

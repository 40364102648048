export default {
  fleetAnalytics: {
    dashboardHeader: "Panel de control de Fleet Analytics",
    dataTable: {
      headers: {
        contacts: "Contactos",
        machines: "Máquinas",
        segments: "Segmentos",
        tenant: "Inquilino"
      },
      machines: {
        count: "Recuento",
        engineHours: "Trabajado",
        serviceAgreements: "Contratos de mantenimiento"
      }
    },
    widgets: {
      segments: "Utilización por segmento",
      utilization: "Utilización por modelo"
    }
  }
}

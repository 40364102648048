export default {
  documents: {
    ce: {
      subTitle: "Documents CE pour Brokk {productId}",
      title: "CE"
    },
    diagrams: {
      subTitle: "Diagrammes pour Brokk {productId}",
      title: "Diagrammes"
    },
    manuals: {
      subTitle: "Manuels pour Brokk {productId}",
      title: "Manuels"
    },
    menuItems: {
      returnform: "Formulaire de retour",
      serviceBulletins: "Bulletins de service",
      serviceInstructions: "Instructions d’entretien",
      serviceManuals: "Manuels d’entretien",
      serviceProtocols: "Protocoles d’entretien",
      warranty: "Réclamations au titre de la garantie"
    },
    returnform: {
      description1:
        "Veuillez utiliser ce formulaire lors du renvoi de matériels à Brokk AB.",
      description2:
        "Avant tout envoi de matériels, veuillez contacter Brokk AB pour demander leur approbation.",
      description3:
        "Les matériels retournés à Brokk AB sans leur approbation préalable seront mis au rebut.",
      subtitle: "Formulaire de retour pour les matériels Brokk."
    },
    serialNoRange: "N° de série : {range}",
    serviceBulletins: {
      action: {
        add: "Ajouter des bulletins de service",
        addSerials: "Ajouter des séries",
        edit: "Modifier des bulletins de service"
      },
      description:
        "Informations portant sur l'amélioration, l'inspection, la réparation ou la modification des engins et outils Brokk.",
      dialogs: {
        errors: {
          couldNotAddServiceBulletins:
            "Impossible d'ajouter des bulletins de service {msg}",
          couldNotRemoveServiceBulletins:
            "Impossible de supprimer des bulletins de service {msg}",
          couldNotUpdateServiceBulletins:
            "Impossible de mettre à jour les bulletins de service {msg}"
        }
      },
      itemName: "bulletins de service",
      messages: {
        failedToDownload: "Échec du téléchargement de {file}",
        itemAdded: "Les bulletins de service ont été ajoutés.",
        itemUpdated: "Les bulletins de service ont été mis à jour.",
        updateDateInfo: "mis à jour {date}",
        updateDocumentInfo: "Document {document} "
      },
      subtitle: "Documents pour les bulletins de service."
    },
    serviceDocuments: {
      action: {
        addSerials: "Ajouter des séries"
      },
      addEditItemForm: {
        descriptionLabel: "Description",
        descriptionPlaceholder: "Entrer la description",
        fileuploadLabel: "Cliquez ici pour sélectionner un fichier .pdf",
        machinesLabel: "Engins",
        partNumberLabel: "Référence",
        partNumberPlaceholder: "Entrer la référence",
        serialLabel: "Séries",
        serialPlaceholder: "Entrer les séries séparées par ’,’",
        uploadFilePlaceholder: "Télécharger un fichier"
      },
      datatable: {
        headers: {
          description: "Description",
          machines: "Engins",
          partNo: "Référence"
        }
      },
      machineSerials: "Séries d’engins"
    },
    serviceInstructions: {
      action: {
        add: "Ajouter des instructions d’entretien",
        addSerials: "Ajouter des séries",
        edit: "Modifier les instructions d'entretien"
      },
      datatable: {
        headers: {
          machines: "Engins",
          partNo: "Référence",
          service: "Entretien"
        }
      },
      description:
        "Instructions concernant les procédures d'installation, d'entretien ou de réglage des différentes pièces et des différents kits.",
      dialogs: {
        errors: {
          couldNotAddServiceInstructions:
            "Impossible d’ajouter des instructions d’entretien {msg}",
          couldNotRemoveServiceInstructions:
            "Impossible de supprimer des instructions d’entretien {msg}",
          couldNotUpdateServiceInstructions:
            "Impossible de mettre à jour les instructions d’entretien {msg}"
        }
      },
      itemName: "instructions d’entretien",
      machineSerials: "Séries d’engins",
      messages: {
        failedToDownload: "Échec du téléchargement de {file}",
        itemAdded: "Les instructions d’entretien ont été ajoutées.",
        itemUpdated: "Les instructions d’entretien ont été mises à jour.",
        updateDateInfo: "mis à jour {date}",
        updateDocumentInfo: "Document {document} "
      },
      subtitle: "Documents pour les instructions d'entretien."
    },
    serviceManuals: {
      description:
        "Manuels portant sur l'entretien, la maintenance et la réparation. Disponible uniquement pour Brokk 40, 90, 110, 150, 180, 250, 330 et SP+.",
      subtitle: "Documents pour les manuels d'entretien."
    },
    serviceProtocols: {
      description1:
        "Vous pouvez télécharger ici tous les protocoles d'entretien disponibles.",
      description2:
        "Le protocole d'entretien est utilisé pour effectuer l'entretien régulier conformément au tableau d'entretien du manuel Brokk.",
      subtitle: "Protocoles d’entretien pour les engins Brokk."
    },
    spareParts: {
      description: "",
      subtitle: "Pièces de rechange"
    },
    technicalData: {
      includedInManual:
        "Les caractéristiques techniques sont incluses dans le manuel. Veuillez consulter le manuel.",
      subTitle: "Caractéristiques techniques pour Brokk {productId}",
      title: "Caractéristiques techniques"
    },
    troubleshooting: {
      includedInManual:
        "Le dépannage est inclus dans le manuel. Veuillez consulter le manuel.",
      subTitle: "Dépannage pour Brokk {productId}",
      title: "Dépannage"
    },
    warranty: {
      description: "",
      subtitle: "Documents de garantie et liens."
    }
  }
}
